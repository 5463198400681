<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="`${$t('status-of-payment')} #${reservationID}`"
    />

    <div class="row justify-content-md-center">
      <div class="col-md-auto col-md-5">
        <b-card v-if="paymentData" class="payment-card text-center">
          <b-card-text>
            <div class="icon-payment-status text-center">
              <icon-times-circle v-if="!paid" class="text-danger" />

              <icon-check-circle v-else class="text-success" />
            </div>
            <h2>{{ paymentStatusMessage }}</h2>
            <hr />

            <div v-html="message" class="mb-2" />

            <div v-if="!this.paymentData.Message">
              <div>
                <strong>{{ $t('payment-details') }}</strong>
              </div>

              <table class="table">
                <tbody>
                  <tr>
                    <td class="text-left">
                      {{ $t('reservation-order') }}
                    </td>
                    <td class="text-right text-primary">
                      <strong>#{{ reservationID }}</strong>
                    </td>
                  </tr>
                  <tr v-if="paymentData.CreatedBy">
                    <td class="text-left">{{ $t('order-created-by') }}</td>
                    <td class="text-right text-primary">
                      <strong>{{ paymentData.CreatedBy }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">{{ $t('payment-outstanding') }}</td>
                    <td class="text-right">
                      <strong
                        >&euro;
                        {{
                          (Math.round(paymentData.TotalAmountDue * 100) / 100)
                            .toFixed(2)
                            .replace('.', ',')
                        }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-if="!paid">
                <hr />
                <a :href="paymentLink" class="btn btn-primary d-block">{{
                  $t('pay-now')
                }}</a>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script>
import {
  getPaymentStatus,
  updatePaymentStatus
} from '@/services/ReservationService'
import IconTimesCircle from '@/components/icons/IconTimesCircle'
import IconCheckCircle from '@/components/icons/IconCheckCircle'
export default {
  name: 'PaymentStatus',
  components: { IconCheckCircle, IconTimesCircle },
  data() {
    return {
      breadCrumbs: [
        { page: 'index', title: this.$t('general.bikeRental'), path: '/' },
        {
          page: 'account',
          title: this.$t('my-account'),
          path: '/account'
        }
      ],
      paymentData: null
    }
  },
  computed: {
    hostedCheckoutID: function() {
      return this.$route.query.hostedCheckoutId
    },
    reservationID: function() {
      return this.$route.params.OrderReservationID
    },
    secretCode: function() {
      return this.$route.query.secretCode
    },
    payID: function() {
      return this.$route.query.PAYID
    },
    orderID: function() {
      return this.$route.params.OrderReservationID
    },
    paymentStatus: function() {
      return this.$route.query.STATUS
    },
    status: function() {
      if (this.paymentData) return this.paymentData.Status
      return null
    },
    paid: function() {
      if (
        this.status === 'Not paid' ||
        this.status === null ||
        this.paymentData.Message
      )
        return false
      return true
    },
    paymentLink: function() {
      if (this.paymentData && !this.paid) return this.paymentData.PaymentLink
      return null
    },
    paymentStatusMessage: function() {
      if (this.paid) return this.$t('payment-received')
      if (this.paymentData.Message) return this.$t('error')

      return this.$t('payment-pending')
    },
    message: function() {
      if (this.paymentData.Message) {
        return this.paymentData.Message
      }

      if (this.paid) {
        return this.$t('you-wil-receive-confirmation-by-email-payment')
      }

      if (!this.paid) {
        return this.$t('payment-not-complete')
      }

      return this.paymentData.Message
    }
  },
  created: async function() {
    if (
      this.secretCode &&
      this.payID &&
      this.orderID &&
      this.paymentStatus == 9
    ) {
      await this.updatePaymentStatus()
    }

    await this.setPaymentData()
  },
  methods: {
    updatePaymentStatus: async function() {
      await updatePaymentStatus({
        secretCode: this.secretCode,
        payID: this.payID,
        orderID: this.orderID,
        status: this.paymentStatus
      })
    },
    setPaymentData: async function() {
      const result = await getPaymentStatus({
        reservationID: this.reservationID
      })
      this.paymentData = result.data
    }
  }
}
</script>
<style lang="scss">
.payment-card {
  td {
    border: none;
  }
  h1 {
    margin-bottom: 0.75rem;
    font-size: 27px;
  }
  .icon-payment-status {
    font-size: 80px;
  }
}
</style>
